/* eslint-disable import/no-extraneous-dependencies */
import { createContextLogger } from '@principal/lumberjack';

const meta = { source: { system: 'life-calculator', component: 'api-authorizer' } };
const log = createContextLogger({ logMeta: meta });

export {
    log as default,
    log
};
