/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { log } from './log-service';
import { buildParams } from './oauthParams';
import { getSecret } from './secret-retriver';

let oktaTokenValue, oktaTokenExpiration;

export const isOktaTokenExpired = () => oktaTokenExpiration < Date.now();

export const getToken = async () => {
    const now = Date.now();
    if (!oktaTokenValue || isOktaTokenExpired()) {
        const secrets = await getSecret('api-authorizer');
        const token = await (
            await axios.post(`${secrets.ENDPOINT}`, buildParams(secrets), {
                headers: { content_type: 'application/x-www-form-urlencoded' }
            })
        ).data;
        log.info(`Api token acquired. Expires in ${token.expires_in}.`);
        oktaTokenExpiration = now + token.expires_in * 1000 - 60000;
        oktaTokenValue = token.access_token;
    }
    log.info(
        `Current Timestamp: ${now}, Token Expires: ${oktaTokenExpiration}, Millis Remaining: ${oktaTokenExpiration - now}`
    );
    return oktaTokenValue;
};
