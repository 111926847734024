/* eslint prefer-template: off */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import DateEntry from '../date-entry';
import Gender from '../gender';
import Height from '../height';
import Weight from '../weight';
import Tobacco from '../tobacco';

import MultipleEntry from '../multiple-input';

import PageProgress from '../page-progress';
import AboutForm from '../quote-about-form';
import QuoteResult from '../quote-result';
import Slider from '../slider';

import ContactForm from '../contact-form';

import QuoteHero from '../../img/quote-hero.png';

import styles from '../../css/quote.module.css';
import infoBlurbStyles from '../../css/info-blurb.module.css';
import { getToken } from '../../token-retriever';
import {
    ABOUT_DOB,
    ABOUT_GENDER,
    ABOUT_STATE,
    ABOUT_HEIGHT,
    ABOUT_WEIGHT,
    ABOUT_TOBACCO,

    QUOTE_PAGE,
    QUOTE_FACE_VALUE,
    QUOTE_TERM_RATES
} from '../../constants';

let token;
const QUOTE_RESULTS_STATES = {
    ok: 0,
    loading: 1,
    norates: 2,
    error: 3
};

const QUOTE_RESULTS_STATE = 'quote:fetch:status';

const quoteValues = state => ({
    dob: DateEntry.State.getDate(state[ABOUT_DOB]),
    gender: Gender.State.getGender(state[ABOUT_GENDER]),
    state: MultipleEntry.State.get(state[ABOUT_STATE]),
    height: Height.State.getHeight(state[ABOUT_HEIGHT]),
    weight: Weight.State.getWeight(state[ABOUT_WEIGHT]),
    tobacco: Tobacco.State.getTobacco(state[ABOUT_TOBACCO]),
    tobaccoYears: Tobacco.State.getTobaccoYears(state[ABOUT_TOBACCO])
});

if (!token) {
    token = getToken();
}
const fetchId = state => {
    const body = {
        ...quoteValues(state),
        faceValue: Slider.State.getValue(state[QUOTE_FACE_VALUE])
    };

    return fetch('/life-calculator/api/v1/quote/get-id', {
        method: 'POST',
        mode: 'cors',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(body)
    })
        .then(res => res.json())
        .then(data => {
            if (!data.success) {
                throw new Error('Failed to get id');
            }

            return data.id;
        });
};

const setHelper = (state, values, key, setter) => ({
    ...state,
    [key]: setter(state[key], values[key])

});

const loadSavedQuoteResults = (state, values, history) => {
    setHelper(state, values, ABOUT_DOB, DateEntry.State.setFromStr);
    setHelper(state, values, ABOUT_GENDER, Gender.State.set);
    setHelper(state, values, ABOUT_HEIGHT, Height.State.set);
    setHelper(state, values, ABOUT_WEIGHT, Weight.State.set);

    state[ABOUT_TOBACCO] = Tobacco.State.set(
        state[ABOUT_TOBACCO],
        values[ABOUT_TOBACCO],
        values.tobaccoYears
    );

    state = QuoteResult.State.insertCalculatorValue(
        state,
        values[QUOTE_FACE_VALUE]
    );

    state[QUOTE_TERM_RATES] = values[QUOTE_TERM_RATES];

    state[QUOTE_PAGE] = PageProgress.State.setPage(
        state[QUOTE_PAGE], 'result', history
    );

    return state;
};

const loadErrorPage = (state, history) => {
    state[QUOTE_RESULTS_STATE] = QUOTE_RESULTS_STATES.error;
    state[QUOTE_PAGE] = PageProgress.State.disableSubsequent(
        PageProgress.State.setPage(
            state[QUOTE_PAGE], 'about', history
        ),
        'about'
    );
    return state;
};

const resultsFetcher = (update, resultsId, history, page) => {
    return fetch(
        `/life-calculator/api/v1/quote/${resultsId}`,
        { method: 'GET', mode: 'cors', headers: { Authorization: `Bearer ${token}` } }
    )
        .then(res => res.json())
        .then(data => {
            if (!data.success) {
                update(state => loadErrorPage(state));
                history.push(page);
                return;
            }

            update(state => loadSavedQuoteResults(state, data.data));

            history.push(page);

            return data.id;
        });
};


const QuoteState = ({
    Initial: () => {
        let pages = PageProgress.State.Initial('/life-calculator/quote');
        pages = PageProgress.State.addPage(pages, 'about', 'About me', true, 'about');
        pages = PageProgress.State.addPage(
            pages, 'result', 'Rates and coverage', false, 'results'
        );

        return {
            [QUOTE_TERM_RATES]: null,
            [QUOTE_RESULTS_STATE]: QUOTE_RESULTS_STATES.ok,
            [QUOTE_PAGE]: pages,
            ...AboutForm.State.Initial(),
            ...QuoteResult.State.Initial()
        };
    },
    Actions: (update) => ({
        requestRates: (state, actions, history) => {
            const formValid = AboutForm.State.validateForm(state, actions);
            if (!formValid) {
                return state;
            }

            update({ [QUOTE_RESULTS_STATE]: QUOTE_RESULTS_STATES.loading });

            // Build up the request body.
            const body = quoteValues(state);

            fetch('/life-calculator/api/v1/quote/termrates', {
                method: 'POST',
                mode: 'cors',
                headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify(body)
            })
                .then(res => (
                    res.json()
                        .then(
                            parsedBody => ({
                                ok: res.ok,
                                result: parsedBody
                            }),
                            () => ({
                                ok: false,
                                result: { success: false }
                            })
                        )
                ))
                .then(({ ok, result }) => {
                    if (!ok) {
                        return update({
                            [QUOTE_RESULTS_STATE]: QUOTE_RESULTS_STATES.error,
                            [QUOTE_PAGE]: PageProgress.State.disableSubsequent(
                                PageProgress.State.setPage(
                                    state[QUOTE_PAGE], 'about', history
                                ),
                                'about'
                            )
                        });
                    }

                    if (result.success) {
                        // TODO: Set page to results
                        return update({
                            [QUOTE_RESULTS_STATE]: QUOTE_RESULTS_STATES.ok,
                            [QUOTE_TERM_RATES]: () => result.rate,
                            [QUOTE_PAGE]: PageProgress.State.setPage(
                                state[QUOTE_PAGE], 'result', history
                            )
                        });
                    }

                    return update({
                        [QUOTE_RESULTS_STATE]: QUOTE_RESULTS_STATES.norates,
                        [QUOTE_PAGE]: PageProgress.State.disableSubsequent(
                            PageProgress.State.setPage(
                                state[QUOTE_PAGE], 'about', history
                            ),
                            'about'
                        )
                    });
                },
                () => update({
                    [QUOTE_RESULTS_STATE]: QUOTE_RESULTS_STATES.error,
                    [QUOTE_PAGE]: PageProgress.State.disableSubsequent(
                        PageProgress.State.setPage(
                            state[QUOTE_PAGE], 'about', history
                        ),
                        'about'
                    )
                }));
        },
        downloadQuotePdf: state => fetchId(state)
            .then(
                id => window.open(
                    `/life-calculator/api/v1/quote/pdf/${id}`,
                    '_blank'
                )
            ),
        getSharableQuoteURL: state => fetchId(state).then(
            id => `${window.location.origin}/life-calculator/quote/my/results/${id}`
        ),
        loadMyQuoteResults: (resultsId, history, page) => resultsFetcher(
            update, resultsId, history, page
        ),
        goBackToQuoteAbout: () => update(state => {
            state[QUOTE_RESULTS_STATE] = QUOTE_RESULTS_STATES.ok;
            state[QUOTE_PAGE] = PageProgress.State.setPage(
                state[QUOTE_PAGE], 'about'
            );

            return state;
        }),
        ...AboutForm.State.Actions(update),
        ...QuoteResult.State.Actions(update),
        ...PageProgress.State.Actions(update)
    })
});

const QuoteHeader = ({ state }) => {
    const location = useLocation();

    if (state[QUOTE_RESULTS_STATE] !== QUOTE_RESULTS_STATES.ok) {
        return null;
    }

    if (PageProgress.State.getCurrent(state['quote:page'], location) === 'result') {
        return <h1 className={styles.sectionTitle}>Life Insurance Quote</h1>;
    }

    return (
        <>
            <div className={styles.hero}>
                <img src={QuoteHero} alt="" />
            </div>
            <h1 className={styles.sectionTitle}>Life Insurance Quote</h1>
            <div className={styles.blurb}>
                <div className={infoBlurbStyles.title}>
                    Why does Principal need this information?
                </div>
                <div className={infoBlurbStyles.body}>
                    We use this information to help us customize a life
                    insurance quote to your needs. We won't use it
                    for other purposes or share it with other organizations.
                </div>
            </div>
        </>
    );
};

QuoteHeader.propTypes = {
    state: PropTypes.shape(
        { [QUOTE_RESULTS_STATE]: PropTypes.number.isRequired }
    ).isRequired
};


const LoadQuoteResults = ({ actions, page }) => {
    const { resultsId } = useParams();
    const history = useHistory();
    useEffect(() => { actions.loadMyQuoteResults(resultsId, history, page); }, []);

    return (
        <div>
            <h1 className={styles.loadingMessage}>
                Please wait while we load your saved quote.
            </h1>
            <div className={styles.loading} />
        </div>
    );
};

LoadQuoteResults.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    page: PropTypes.string.isRequired
};


const FetchingResultsPage = () => (
    <div>
        <h1 className={styles.loadingMessage}>
            Please wait while we load your personalized rates.
        </h1>
        <div className={styles.loading} />
    </div>
);

const GoBackButton = ({ actions }) => (
    <div className={styles.goBackButton}>
        <button className="pds-button pds-button-ghost"
            type="submit"
            onClick={(evt) => {
                evt.preventDefault();
                actions.goBackToQuoteAbout();
                window.scrollTo(0, 0);
                window.dataLayer.push({
                    event: 'routeChange',
                    pagePath: '/life-calculator/quote/about',
                    pageTitle: 'Quote About Me'
                });
            }}
        >
            Go Back
        </button>
    </div>
);

GoBackButton.propTypes = {
    actions: PropTypes.shape(
        { goBackToQuoteAbout: PropTypes.func.isRequired }
    ).isRequired
};

const ErrorResultsPage = ({ actions }) => (
    <div>
        <h1 className={styles.errorMessage}>
            We're sorry. There was an error loading your rates. Please try
            again or contact us for a quote.
        </h1>
        <GoBackButton actions={actions} />
    </div>
);

ErrorResultsPage.propTypes = {
    actions: PropTypes.shape(
        { goBackToQuoteAbout: PropTypes.func.isRequired }
    ).isRequired
};

const NoResultsPage = ({ actions }) => (
    <div>
        <div className={styles.noRatesMessage}>
            Based on the information you provided, we're unable to offer you a rate quote today.
            You may want to try again to ensure your inputs are accurate.
        </div>
        <GoBackButton actions={actions} />
    </div>
);

NoResultsPage.propTypes = {
    actions: PropTypes.shape(
        { goBackToQuoteAbout: PropTypes.func.isRequired }
    ).isRequired
};

const DisplaySwitch = ({ state, actions }) => {
    const location = useLocation();

    if (state[QUOTE_RESULTS_STATE] === QUOTE_RESULTS_STATES.loading) {
        return <FetchingResultsPage />;
    }

    if (state[QUOTE_RESULTS_STATE] === QUOTE_RESULTS_STATES.error) {
        return <ErrorResultsPage actions={actions} />;
    }

    if (state[QUOTE_RESULTS_STATE] === QUOTE_RESULTS_STATES.norates) {
        return <NoResultsPage actions={actions} />;
    }

    const pageMap = {
        about: () => (
            <AboutForm.Component id="quote:about"
                state={state}
                actions={actions}
            />
        ),
        result: () => (
            <QuoteResult.Component id="quote:result"
                state={state}
                actions={actions}
            />
        ),
        goToAbout: () => {
            const aboutUrl = PageProgress.State.getPageUrlByKey(
                state['quote:page'], 'about'
            );

            return <Redirect to={aboutUrl} />;
        }
    };

    return PageProgress.State.renderPage(
        state['quote:page'], location, pageMap, 'goToAbout'
    );
};

DisplaySwitch.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.shape(
        { [QUOTE_RESULTS_STATE]: PropTypes.number.isRequired }
    ).isRequired
};

// eslint-disable-next-line no-unused-vars
const Contact = ({ state, actions }) => {
    const { option } = useParams();
    useEffect(() => {
        actions.showModalContent(
            'modal',
            () => (option === 'advisor' ? <span>Find a financial professional</span> : <span>Schedule a call</span>),
            (currentState, currentActions) => <ContactForm.Component state={currentState} actions={currentActions} advisor={option === 'advisor'} />
        );
    },
    []);
    return null;
};

const QuoteComponent = ({ state, actions }) => (
    <Switch>
        <Route path="/life-calculator/quote/my/results/:resultsId">
            <LoadQuoteResults actions={actions} page="/life-calculator/quote/results" />
        </Route>
        <Route path="/life-calculator/quote/schedule/:resultsId">
            <LoadQuoteResults actions={actions} page="/life-calculator/quote/contact/schedule" />
        </Route>
        <Route path="/life-calculator/quote/meet/:resultsId">
            <LoadQuoteResults actions={actions} page="/life-calculator/quote/contact/advisor" />
        </Route>
        <Route path="/life-calculator/quote/contact/:option">
            <div className={styles.container}>
                <PageProgress.Component id={QUOTE_PAGE}
                    state={state}
                    actions={actions}
                />
                <QuoteHeader state={state} />
                <DisplaySwitch state={state} actions={actions} />
                <Contact state={state} actions={actions} />
            </div>
        </Route>
        <Route path="/life-calculator/quote">
            <div className={styles.container}>
                <PageProgress.Component id={QUOTE_PAGE}
                    state={state}
                    actions={actions}
                />
                <main>
                    <QuoteHeader state={state} />
                    <DisplaySwitch state={state} actions={actions} />
                </main>
            </div>
        </Route>
    </Switch>
);


QuoteComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    // The specifics of state are validated by the sub-views. We'll only ensure
    // that state is present and an object at this level.
    // eslint-disable-next-line react/forbid-prop-types
    state: PropTypes.object.isRequired
};


export default {
    State: QuoteState,
    Component: QuoteComponent
};
