/* eslint-disable import/prefer-default-export */

import querystring from 'querystring';

const API_SCOPE = 'api://life-calculator/operation.all';

export const buildParams = (secrets) => {
    const params = {
        grant_type: 'client_credentials',
        client_id: secrets.key,
        client_secret: secrets.secret,
        scope: API_SCOPE
    };
    return querystring.stringify(params);
};
